import { useAuthStore } from '~/stores/auth';

export function useCheckoutRoute() {
    const authStore = useAuthStore();
    const { authenticated } = storeToRefs(authStore);

    const { locale } = useI18n();
    const localeRoute = useLocaleRoute();

    const checkoutRoute = computed(() => {
        if (authenticated.value) {
            return localeRoute(
                { name: 'checkout-user-shipping' },
                locale.value,
            );
        }
        return localeRoute({ name: 'checkout' }, locale.value);
    });
    return checkoutRoute;
}
